import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "sub-footer-wrapper"
};
const _hoisted_2 = {
  class: "copyright"
};
import Newsletter from './Newsletter.vue';
import SocialLinks from './SocialLinks.vue';
export default {
  __name: 'Footer',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_col = _resolveComponent("el-col");
      return _openBlock(), _createElementBlock("footer", null, [_createVNode(_component_el_row, {
        class: "footer-wrapper",
        "data-lazy-bg": "https://antares-web-frontend.sfo3.cdn.digitaloceanspaces.com/backgrounds/images_webp/Black_background_with_blue_to_purple_smoke_x1.webp",
        "data-lazy-bg-tablet": "https://antares-web-frontend.sfo3.cdn.digitaloceanspaces.com/backgrounds/images_webp/Black_background_with_blue_to_purple_smoke_2x_mobile.webp",
        "data-lazy-bg-mobile": "https://antares-web-frontend.sfo3.cdn.digitaloceanspaces.com/backgrounds/images_webp/Black_background_with_blue_to_purple_smoke_mobile.webp",
        id: "footer"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, null, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "footer"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "title"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h4", {
                style: {
                  "font-weight": "500"
                }
              }, " Enhance Your Voice, Amplify Your Artistry ", -1)])),
              _: 1
            }), _createVNode(_component_el_col, null, {
              default: _withCtx(() => [_createVNode(_component_el_row, {
                class: "footer-nav"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  xs: {
                    span: 24
                  },
                  sm: {
                    span: 8
                  },
                  md: {
                    span: 8
                  },
                  lg: {
                    span: 6
                  }
                }, {
                  default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("h5", null, "Company", -1)), _createVNode(_component_router_link, {
                    to: {
                      name: 'About'
                    }
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", null, "About Us", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'Press'
                    }
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("div", null, "Press", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      path: '/affiliate-program'
                    }
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("div", null, "Affiliate Program", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'EduSiteLicenseInquiry'
                    }
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("div", null, "Academic Request", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      path: '/careers'
                    }
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("div", null, "Careers", -1)])),
                    _: 1
                  })]),
                  _: 1
                }), _createVNode(_component_el_col, {
                  sm: {
                    span: 8
                  },
                  md: {
                    span: 8
                  },
                  lg: {
                    span: 6
                  }
                }, {
                  default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("h5", null, "Products", -1)), _createVNode(_component_router_link, {
                    to: {
                      name: 'Auto-Tune'
                    }
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("div", null, "Auto-Tune Editions", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'VocalEffects'
                    }
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("div", null, "Vocal Effects", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'Subscriptions',
                      params: {
                        slug: 'unlimited'
                      }
                    }
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [_createElementVNode("div", null, "Subscription Plan", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'Compare'
                    }
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [_createElementVNode("div", null, "Compare Auto-Tune Editions", -1)])),
                    _: 1
                  })]),
                  _: 1
                }), _createVNode(_component_el_col, {
                  sm: {
                    span: 8
                  },
                  md: {
                    span: 8
                  },
                  lg: {
                    span: 6
                  }
                }, {
                  default: _withCtx(() => [_cache[18] || (_cache[18] = _createElementVNode("h5", null, "Support", -1)), _cache[19] || (_cache[19] = _createElementVNode("a", {
                    href: "https://antarestech.force.com/s/",
                    target: "_blank"
                  }, [_createElementVNode("div", null, "Customer Support")], -1)), _cache[20] || (_cache[20] = _createElementVNode("a", {
                    href: "https://antarestech.force.com/s/topic/0TO4V000000oNAZWA2/faqs/",
                    target: "_blank"
                  }, [_createElementVNode("div", null, "FAQ")], -1)), _createVNode(_component_router_link, {
                    to: {
                      name: 'LearningCenter'
                    }
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [_createElementVNode("div", null, "Tutorials", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'Artists'
                    }
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [_createElementVNode("div", null, "Artists", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      path: '/demo-downloads'
                    }
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [_createElementVNode("div", null, "Demo Downloads", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      path: '/daw-compatibility'
                    }
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [_createElementVNode("div", null, "DAW Compatibility", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'SoftwareDownload'
                    }
                  }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [_createElementVNode("div", null, "Download Manager", -1)])),
                    _: 1
                  }), _createVNode(_component_router_link, {
                    to: {
                      name: 'Documentation'
                    }
                  }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [_createElementVNode("div", null, "Product Manuals", -1)])),
                    _: 1
                  })]),
                  _: 1
                }), _createVNode(_component_el_col, {
                  md: {
                    span: 8
                  },
                  lg: {
                    span: 6
                  }
                }, {
                  default: _withCtx(() => [_createVNode(Newsletter)]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
        class: "sub-footer"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 14,
          lg: 16,
          xl: 16,
          class: "trademark"
        }, {
          default: _withCtx(() => [_cache[28] || (_cache[28] = _createElementVNode("div", null, [_createElementVNode("span", {
            ignore: ""
          }, "Evo™"), _createTextVNode(" and "), _createElementVNode("span", {
            ignore: ""
          }, "Auto-Motion™"), _createTextVNode(" are trademarks and "), _createElementVNode("span", {
            ignore: ""
          }, "Auto-Tune®"), _createTextVNode(", "), _createElementVNode("span", {
            ignore: ""
          }, "Antares®"), _createTextVNode(", "), _createElementVNode("span", {
            ignore: ""
          }, "AVOX®"), _createTextVNode(", "), _createElementVNode("span", {
            ignore: ""
          }, "Harmony Engine®"), _createTextVNode(", "), _createElementVNode("span", {
            ignore: ""
          }, "Mic Mod®"), _createTextVNode(", and "), _createElementVNode("span", {
            ignore: ""
          }, "Solid-Tune®,"), _createTextVNode(" are registered trademarks of "), _createElementVNode("span", {
            ignore: ""
          }, "Antares Audio Technologies"), _createTextVNode(". ")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, " All Materials ©" + _toDisplayString(new Date().getFullYear()) + " Antares Audio Technologies. ", 1), _createVNode(_component_router_link, {
            to: {
              name: 'PrivacyPolicy'
            }
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode(" Privacy Policy ")])),
            _: 1
          }), _cache[25] || (_cache[25] = _createElementVNode("span", {
            style: {
              "color": "var(--el-color-white)"
            }
          }, "·", -1)), _createVNode(_component_router_link, {
            to: {
              name: 'RefundsPolicy'
            }
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode(" Refunds Policy ")])),
            _: 1
          }), _cache[26] || (_cache[26] = _createElementVNode("span", {
            style: {
              "color": "var(--el-color-white)"
            }
          }, "·", -1)), _createVNode(_component_router_link, {
            to: {
              path: '/terms-of-service'
            }
          }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [_createTextVNode(" Terms of Service ")])),
            _: 1
          }), _cache[27] || (_cache[27] = _createElementVNode("span", {
            style: {
              "color": "var(--el-color-white)"
            }
          }, "·", -1)), _createVNode(_component_router_link, {
            to: {
              path: '/software-attributions'
            }
          }, {
            default: _withCtx(() => _cache[24] || (_cache[24] = [_createTextVNode(" Software Attributions ")])),
            _: 1
          })])]),
          _: 1
        }), _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 10,
          lg: 8,
          xl: 8,
          style: {
            "display": "flex",
            "justify-content": "flex-end"
          }
        }, {
          default: _withCtx(() => [_createVNode(SocialLinks)]),
          _: 1
        })]),
        _: 1
      })])]);
    };
  }
};