import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  href: "https://www.facebook.com/AutoTune/",
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": "Auto-Tune Facebook Profile"
};
const _hoisted_2 = {
  href: "https://www.tiktok.com/@antaresautotune",
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": "Auto-Tune TikTok Profile"
};
const _hoisted_3 = {
  href: "https://www.threads.net/@autotune",
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": "Auto-Tune Threads Profile"
};
const _hoisted_4 = {
  href: "https://www.twitter.com/AntaresAudio",
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": "Auto-Tune X Profile"
};
const _hoisted_5 = {
  href: "https://www.instagram.com/autotune/",
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": "Auto-Tune Instagram Profile"
};
const _hoisted_6 = {
  href: "https://www.linkedin.com/company/antares-autotune/",
  target: "_blank",
  rel: "noopener noreferrer",
  "aria-label": "Auto-Tune LinkedIn Profile"
};
import { Facebook, Instagram, X, TikTok, LinkedIn, Threads } from "@/assets/SVGs/AutoTuneIconLib";
export default {
  __name: 'SocialLinks',
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["social", {
          darkmode: __props.darkmode
        }])
      }, [_createElementVNode("div", {
        class: _normalizeClass(["links", {
          vertical: __props.vertical
        }])
      }, [_createElementVNode("div", null, [_createElementVNode("a", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_unref(Facebook), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_unref(TikTok), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_unref(Threads), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_4, [_createElementVNode("div", null, [_createVNode(_unref(X), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_unref(Instagram), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_6, [_createElementVNode("div", null, [_createVNode(_unref(LinkedIn), {
        class: "logo"
      })])])])], 2)], 2);
    };
  }
};