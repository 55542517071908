import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createCommentVNode as _createCommentVNode } from "vue";
import NavBar from '@/components/Navigation/NavBar';
import Footer from '@/components/Footer';
import ConsentBanner from '@/components/PrivacyPolicy/ConsentBanner.vue';
import Cart from '@/components/Cart';
import AuthModal from '@/components/AuthModal';
import UpgradeModal from '@/components/UpgradeModal';
import ProductOptionsModal from '@/components/ProductComponents/ProductOptionsModal.vue';
import VideoModal from '@/components/VideoModal.vue';
import FootballModal from '@/components/FootballModal/FootballModal.vue';
import ProductFreebieModal from '@/components/ProductFreebieModal';
import { computed, ref, provide, onMounted, onBeforeUnmount, inject, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { consentStatus } from '@/composables/consent';
import { initializeWW } from '@/composables/windowWatcher';
import { getFsDataObj } from '@/helpers';
import { ElMessage } from 'element-plus';
import { watchFastSpring } from '@/classes/FastSpring';
export default {
  __name: 'CoreLayout',
  setup(__props) {
    const route = useRoute();
    const store = useStore();
    const user = inject('user');
    const footballModal = ref(null);
    const authModal = ref(null);
    const video = ref(null);
    const purchaseModal = ref(null);
    const validRoute = computed(() => {
      return route.name && !route.meta.hideFooter;
    });
    const showConsentBanner = computed(() => {
      return consentStatus.value === -1 && !route.fullPath.includes('my-account');
    });
    const openPopupModal = type => {
      footballModal.value.openModal(type);
    };
    const closePopupModal = () => {
      footballModal.value.closeModal();
    };

    /**
     * Open Authentication Modal.
     *
     * If a destination object is passed in, will redirect the user to the specified page after Account Sign-in or Creation is completed.
     *
     * @param {RouteLocationRaw} dest - (optional) vue-router RouteLocationRaw object
     */
    const openAuthModal = dest => {
      authModal.value.openModal(dest);
    };

    /**
     * Close Authentication Modal
     */
    const closeAuthModal = () => {
      authModal.value.closeModal();
    };
    const addAuthCb = (fn, openImmediately = false) => authModal.value.addCallback(fn, openImmediately);

    /**
     * Open Product Purchase Options modal handler
     *
     * Takes in an optional product object & opens the Product Purchase Options modal
     * Product should be an object retrieved from FS.
     * @param {object} product
     */
    const openProductOptions = (product = null) => {
      // Default values for openModal point to ATU anyway
      if (!product || product.name.includes('Auto-Tune Unlimited')) {
        purchaseModal.value.openModal();
        return;
      }
      const urlData = product.url.match(/[^/]+/g);
      const cards = [urlData[urlData.length - 1]];
      purchaseModal.value.openModal({
        cards
      });
    };
    const openSpecificProductOptions = ({
      header,
      subheader,
      opts = []
    }) => {
      if (!(opts && Array.isArray(opts))) {
        opts = ['unlimited'];
      }
      opts = opts.filter(opt => typeof opt === 'string');
      purchaseModal.value.openModal({
        header,
        subheader,
        cards: opts,
        exact: true
      });
    };

    /**
     * Close Product Purchase Options modal handler
     *
     * Will first close the modal, then clear the product after some time.
     */
    const closeProductOptions = () => {
      purchaseModal.value.closeModal();
    };

    /**
     * Open Free Trial Modal.
     *
     * Accepts optional "product" param that can be either the fs sku or the fsData object to display only trials containing said product
     *
     * Accepts optional "allTrials" param that toggles displaying all trials or just ATU if product param is omitted; defaults true
     *
     * @param {String|Object} product
     */
    const openTrialModal = product => {
      const targets = [];
      let fsData = null;
      const handleError = error => {
        const errorMsg = ElMessage({
          onClick: () => {
            errorMsg.close();
          },
          message: 'An unexpected error has occured; we apologize for the inconvenience',
          type: 'error',
          duration: 5000,
          customClass: 'clickable-notif',
          offset: 20
        });
        console.error(error);
      };
      const path = typeof product === 'string' ? product : product?.path;
      if (product && typeof product === 'object') {
        fsData = product;
      } else {
        fsData = store.getters.getFsDataObj(path);
      }
      if (!(path && fsData)) {
        targets.push('atu-trial'); // Default to showing just ATU as well
      } else if (fsData.type !== 'perpetual') {
        const parts = fsData.url?.split('/').filter(part => part) || [];
        const subType = parts.length ? parts[parts.length - 1] : null;
        switch (subType) {
          case 'unlimited':
            targets.push('atu-trial');
            break;
          default:
            handleError(`Product "${path}" does not have a trial or has a misconfigured url`);
            return;
        }
      } else {
        // Build list of trials that contain the product
        const atu = store.getters.getFsDataObj('auto-tune-unlimited-monthly');
        if (atu.included_products.includes(path)) {
          targets.push('atu-trial');
        }
        if (!targets.length) {
          handleError(`Product "${path}" is not included in any trial`);
          return;
        }
      }
      purchaseModal.value.openModal({
        header: "<span style='color: var(--el-color-primary)'>Start Your Free Trial</span>",
        subheader: 'Try all our plug-ins including Auto-Tune Pro 11 by starting a 14-day free trial of Auto-Tune Unlimited',
        cards: targets
      });
    };

    /**
     * Close Free Trial Modal
     */
    const closeTrialModal = () => {
      purchaseModal.value.closeModal();
    };

    /**
     * Calls appropriate handler functions if the URL contains query string parameters.
     * @param {object} to
     */
    const handleQueryStringTrigger = to => {
      showProductOptionsOnQueryStringTrigger(to);
      loadImpactRadius(to);
    };

    /**
     * Shows the product options modal when the 'show_cards' query string param is passed.
     * @param {object} to
     */
    const showProductOptionsOnQueryStringTrigger = to => {
      if (!to.query.show_cards) {
        return;
      }
      const path = to.query.show_cards;
      const continuation = () => {
        const fsData = store.getters.getFsData;
        const fsDataObj = getFsDataObj(fsData, path);
        if (!fsDataObj) {
          return;
        }
        openProductOptions(fsDataObj);
      };
      watchFastSpring(() => continuation());
    };

    /**
     * Loads Impact Radius if URL contains affiliate tracking info
     * @param {object} to
     */
    const loadImpactRadius = async to => {
      if (!to.query.irclickid) {
        return;
      }
      try {
        const {
          default: ImpactRadius
        } = await import('../classes/ImpactRadius');
        const ir = reactive(new ImpactRadius(user));
        if (typeof ir.loadScript !== 'function') {
          return;
        }
        provide('ir', ir);
        ir.loadScript();
      } catch (error) {
        console.error('Error loading ImpactRadius:', error);
      }
    };

    /**
     * Provide the handler functions to any children that wishes to open the modals
     * Allows us to control modal without polluting vuex for UI management
     */
    provide('openPopupModal', openPopupModal);
    provide('closePopupModal', closePopupModal);
    provide('openAuthModal', openAuthModal);
    provide('closeAuthModal', closeAuthModal);
    provide('addAuthCb', addAuthCb);
    provide('openTrialModal', openTrialModal);
    provide('closeTrialModal', closeTrialModal);
    provide('videoModal', video);
    // Provide access to purchaseModal itself to allow full content control over header & subheader, if we need to
    provide('purchaseModal', purchaseModal);
    // Might be deprecated if we choose to handle open/closures directly on the purchaseModal
    provide('openProductOptions', openProductOptions);
    provide('openSpecificProductOptions', openSpecificProductOptions);
    provide('closeProductOptions', closeProductOptions);
    initializeWW();
    onMounted(async () => {
      store.commit('setEventBusCallbackFunction', {
        name: 'queryStringTrigger',
        cb: handleQueryStringTrigger
      });
      try {
        if (user?.id && typeof user?.fetchUserSummary === 'function') {
          await user.fetchUserSummary();
        }
      } catch (e) {
        if (e.message !== '401') {
          console.error(e);
        }
      }
    });
    onBeforeUnmount(() => {
      store.commit('setEventBusCallbackFunction', {
        name: 'queryStringTrigger',
        cb: null
      });
    });
    return (_ctx, _cache) => {
      const _component_el_header = _resolveComponent("el-header");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_component_el_container, {
        id: "scroll-body"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          id: "acct-app-header"
        }, {
          default: _withCtx(() => [_createVNode(_unref(NavBar), {
            isSpacer: "",
            style: {
              "visibility": "hidden"
            }
          }), _createVNode(_unref(NavBar), {
            style: {
              "position": "fixed",
              "top": "0",
              "z-index": "1999"
            }
          })]),
          _: 1
        }), _createVNode(_component_router_view, {
          id: "main-body",
          class: "viewport-min-height-minus-navbar"
        }, {
          default: _withCtx(({
            Component
          }) => [_createVNode(_Transition, {
            name: "route",
            mode: "out-in"
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
            _: 2
          }, 1024)]),
          _: 1
        }), validRoute.value ? (_openBlock(), _createBlock(_unref(Footer), {
          key: 0
        })) : _createCommentVNode("", true), _createVNode(_Transition, {
          name: "consent",
          appear: ""
        }, {
          default: _withCtx(() => [showConsentBanner.value ? (_openBlock(), _createBlock(ConsentBanner, {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_unref(Cart)), _createVNode(_unref(AuthModal), {
          ref_key: "authModal",
          ref: authModal
        }, null, 512), _createVNode(FootballModal, {
          ref_key: "footballModal",
          ref: footballModal
        }, null, 512), _createVNode(_unref(UpgradeModal)), _createVNode(ProductOptionsModal, {
          ref_key: "purchaseModal",
          ref: purchaseModal
        }, null, 512), _createVNode(VideoModal, {
          ref_key: "video",
          ref: video
        }, null, 512), _createVNode(_unref(ProductFreebieModal))]),
        _: 1
      });
    };
  }
};