import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "fullwidth"
};
const _hoisted_2 = {
  class: "flex-row flex-centered",
  id: "nav-bar-container"
};
const _hoisted_3 = {
  class: "flex-col fullwidth"
};
const _hoisted_4 = {
  class: "flex-row fullwidth main-nav-bar"
};
const _hoisted_5 = {
  class: "flex-col fullheight",
  style: {
    "flex": "1",
    "align-items": "flex-start",
    "justify-content": "center"
  }
};
const _hoisted_6 = {
  class: "flex-row flex-row-start"
};
const _hoisted_7 = {
  class: "flex-col flex-centered fullheight",
  style: {
    "padding-right": "20px"
  }
};
const _hoisted_8 = {
  class: "navbar-img-override"
};
const _hoisted_9 = {
  class: "image-slot"
};
const _hoisted_10 = {
  class: "image-slot"
};
const _hoisted_11 = {
  key: 0,
  class: "flex-col fullheight flex-centered"
};
const _hoisted_12 = {
  class: "flex-col flex-centered hidden-sm-and-down"
};
const _hoisted_13 = {
  class: "flex-col fullheight",
  style: {
    "flex": "1",
    "align-items": "flex-end",
    "justify-content": "center"
  }
};
const _hoisted_14 = {
  class: "flex-row",
  style: {
    "align-items": "center",
    "justify-content": "flex-end"
  }
};
const _hoisted_15 = {
  key: 0,
  id: "stickyCTAHook"
};
import { ref, inject } from 'vue';
import NavMenuLang from './NavMenuLang.vue';
import NavBarMenu from './NavBarMenu.vue';
import NavBarOptions from './NavBarOptions.vue';
import NavMenuOverlay from './NavMenuOverlay.vue';
import { Picture, MoreFilled } from '@element-plus/icons-vue';
import * as modalOps from "@/composables/modal.js";
import TryFreeBtn from '@/components/TryFreeBtn';
import { currentBP } from "@/composables/windowWatcher.js";
import MiniBanner from '@/components/MiniBanner.vue';
export default {
  __name: 'NavBar',
  props: {
    isSpacer: Boolean
  },
  setup(__props) {
    // Data
    const navOverlayVisible = ref(false);
    const localize = inject('localize');

    // Methods

    const openNavOverlay = () => {
      navOverlayVisible.value = true;
      modalOps.registerModal('navOverlay');
    };
    const closeNavOverlay = () => {
      navOverlayVisible.value = false;
      modalOps.unregisterModal('navOverlay');
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_image = _resolveComponent("el-image");
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [!__props.isSpacer ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
        type: "primary",
        size: "large",
        class: "gray-base",
        plain: "",
        icon: _unref(MoreFilled),
        onClick: openNavOverlay,
        circle: ""
      }, null, 8, ["icon"])]), _createVNode(_component_router_link, {
        to: {
          name: 'Home'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_image, {
          alt: "Antares Audio Technologies logo",
          src: "https://antares-web-frontend.sfo3.cdn.digitaloceanspaces.com/company-logo/Auto-Tune_white_logo_with_green_A_wave.webp",
          style: {
            "height": "100%"
          },
          class: "hidden-xs-only"
        }, {
          error: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createVNode(_component_el_icon, {
            size: 30
          }, {
            default: _withCtx(() => [_createVNode(_unref(Picture))]),
            _: 1
          })])]),
          _: 1
        }), _createVNode(_component_el_image, {
          alt: "Antares Audio Technologies logo mobile",
          src: "https://antares-web-frontend.sfo3.cdn.digitaloceanspaces.com/company-logo/Antares%20mobile.webp",
          style: {
            "height": "100%"
          },
          class: "hidden-sm-and-up"
        }, {
          error: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createVNode(_component_el_icon, {
            size: 30
          }, {
            default: _withCtx(() => [_createVNode(_unref(Picture))]),
            _: 1
          })])]),
          _: 1
        })])]),
        _: 1
      })])]), !['xs', 'sm'].includes(_unref(currentBP)) ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(NavBarMenu)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_12, [_createVNode(_unref(TryFreeBtn))]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [typeof _unref(localize) === 'object' ? (_openBlock(), _createBlock(NavMenuLang, {
        key: 0,
        style: {
          "margin": "0 10px"
        },
        "color-override": "white",
        "always-dropdown": ""
      })) : _createCommentVNode("", true), _createVNode(NavBarOptions)])])], 64)) : _createCommentVNode("", true)]), _createVNode(MiniBanner, {
        isSpacer: __props.isSpacer
      }, null, 8, ["isSpacer"]), !__props.isSpacer ? (_openBlock(), _createElementBlock("div", _hoisted_15)) : _createCommentVNode("", true)])]), _createVNode(NavMenuOverlay, {
        onClose: closeNavOverlay,
        visible: navOverlayVisible.value
      }, null, 8, ["visible"])]);
    };
  }
};