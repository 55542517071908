import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "beta-form-container flex-row"
};
const _hoisted_2 = {
  key: 0,
  class: "fill-container flex-col signup-submitted"
};
const _hoisted_3 = {
  class: "flex-row"
};
const _hoisted_4 = {
  class: "flex-row"
};
const _hoisted_5 = {
  class: "flex-row"
};
const _hoisted_6 = {
  class: "flex-row"
};
const _hoisted_7 = {
  style: {
    "max-width": "100px",
    "margin-right": "15px"
  }
};
const _hoisted_8 = {
  style: {
    "flex": "1 0 auto"
  }
};
import { reactive, computed, inject, watch } from 'vue';
import { useStore } from "vuex";
import { validateEmail } from "@/helpers";
import Tag from "@/components/Typography/Tag.vue";
import HttpClient from '@/classes/HttpClient';
export default {
  __name: 'WpBetaForm',
  props: {
    beta: {
      required: true,
      type: String
    }
  },
  setup(__props) {
    const store = useStore();
    const user = inject("user");
    const props = __props;
    const state = reactive({
      loading: false,
      error: false,
      submitted: false
    });
    const form = reactive({
      first_name: user?.pi_first_name || "",
      last_name: user?.pi_last_name || "",
      email: user?.email || "",
      birthyear: null,
      fax: false
    });

    /**
     * 
     * Computed Properties
     * 
     */

    const birthyears = computed(() => {
      const years = [];
      for (let i = 1930; i <= new Date().getFullYear(); i++) {
        years.unshift(i);
      }
      return years;
    });
    const canSubmit = computed(() => {
      for (let [key, val] of Object.entries(form)) {
        if (key === "fax") continue;
        if (!val) return false;
      }
      return validateEmail(form.email);
    });
    const hasUserInfo = computed(() => {
      return !!(user?.email && user?.pi_first_name && user?.pi_last_name);
    });

    /**
     * 
     * Methods
     * 
     */

    /**
     * Submit the beta form
     */
    const submit = async () => {
      if (!canSubmit.value) {
        return;
      }
      try {
        state.loading = true;
        state.error = false;
        state.submitted = false;
        const args = {
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          fax: form.fax,
          birthyear: form.birthyear,
          cache_key: store.getters.getCacheKey
        };
        const client = new HttpClient({
          path: `/laravel/rest/${props.beta}betasignup`
        });
        const resp = await client.post(args);
        if (resp.status !== 200) {
          throw new Error(resp.status);
        }
        state.submitted = true;
      } catch (e) {
        state.error = true;
      } finally {
        state.loading = false;
      }
    };

    /**
     * Reset the form
     */
    const resetForm = () => {
      form.first_name = user?.pi_first_name || "";
      form.last_name = user?.pi_last_name || "";
      form.email = user?.email || "";
      form.birthyear = null;
      form.fax = false;
    };

    /**
     * 
     * Lifecycle Hooks & Watchers
     * 
     */

    watch(user, resetForm);
    return (_ctx, _cache) => {
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [state.submitted ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, () => [_createVNode(_component_el_row, {
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("h3", {
          style: {
            "font-size": "28px"
          }
        }, "Thank you for signing up.", -1)])),
        _: 1
      }), _createVNode(_component_el_row, {
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" Please check your email for the link to download the iOS app. ")])),
        _: 1
      })])])) : (_openBlock(), _createBlock(_component_el_form, {
        key: 1,
        class: "fill-container flex-col beta-form",
        model: form
      }, {
        default: _withCtx(() => [state.error ? (_openBlock(), _createBlock(Tag, {
          key: 0,
          type: "danger",
          style: {
            "margin-bottom": "15px",
            "text-align": "center"
          }
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("b", null, "An unexpected error has occured; we apologize for the inconvenience.", -1)])),
          _: 1
        })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
          disabled: state.loading || hasUserInfo.value,
          modelValue: form.first_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.first_name = $event),
          placeholder: "First Name"
        }, null, 8, ["disabled", "modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_input, {
          disabled: state.loading || hasUserInfo.value,
          modelValue: form.last_name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.last_name = $event),
          placeholder: "Last Name"
        }, null, 8, ["disabled", "modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_input, {
          disabled: state.loading || hasUserInfo.value,
          modelValue: form.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.email = $event),
          placeholder: "Email"
        }, null, 8, ["disabled", "modelValue"])]), _createVNode(_component_el_form_item, {
          style: {
            "display": "none"
          },
          label: "Contact me via fax"
        }, {
          default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
            type: "checkbox",
            name: "fax",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.fax = $event)
          }, null, 512), [[_vModelCheckbox, form.fax]])]),
          _: 1
        }), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_select, {
          modelValue: form.birthyear,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form.birthyear = $event),
          disabled: state.loading,
          placeholder: "Birthyear"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(birthyears.value, n => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: n,
              label: n,
              value: n
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue", "disabled"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_button, {
          type: "primary",
          class: "fill-container",
          style: {
            "height": "100%"
          },
          disabled: state.loading || !canSubmit.value,
          loading: state.loading,
          onClick: submit
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" Submit ")])),
          _: 1
        }, 8, ["disabled", "loading"])])])]),
        _: 1
      }, 8, ["model"]))]);
    };
  }
};