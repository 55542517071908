import "core-js/modules/es.array.push.js";
import CoreLayout from '../layouts/CoreLayout.vue';
import { ref, onMounted, onBeforeUnmount, markRaw } from 'vue';
import store from '../store';
import { useRouter } from 'vue-router';
import { ElNotification } from 'element-plus';
export default {
  name: 'AccountApp',
  components: {
    CoreLayout
  },
  setup() {
    const globalNotification = ref(null);
    const router = useRouter();

    /**
     * Global notificatiion handler
     * @return {object}
     */
    const globalNotify = () => {
      const show = ({
        duration = 10000,
        customClass = 'notif',
        onClick = null,
        title,
        type,
        message
      }, routeDestination) => {
        if (routeDestination) {
          onClick = () => {
            router.push(routeDestination);
            globalNotification.value.close();
            setTimeout(() => globalNotification.value = null, 1000);
          };
        }
        globalNotification.value = ElNotification({
          duration,
          customClass,
          onClick,
          title,
          type,
          message
        });
      };
      const close = () => {
        if (globalNotification.value && typeof globalNotification.value.close === 'function') {
          globalNotification.value.close();
          setTimeout(() => globalNotification.value = null, 1000);
        }
      };
      return {
        show,
        close
      };
    };
    onMounted(() => {
      store.commit('setEventBusCallbackFunction', {
        name: 'globalNotify',
        cb: markRaw(globalNotify)
      });
    });
    onBeforeUnmount(() => {
      store.commit('setEventBusCallbackFunction', {
        name: 'globalNotify',
        cb: null
      });
    });
    return {
      globalNotification,
      globalNotify
    };
  }
};